import React from 'react'
import { Link } from 'react-router-dom'
import Rectangle24exclamation from "../images/exclamation-triangle-fill.svg";
const Erorr404 = () => {
    return (
        <>
            <section className='content'>
                <div className='error-page'>
                    <h2 className="headline"> 404</h2>
                    <div className='headline'>
                        <div className="error-content">
                            <h3>
                                <img src={Rectangle24exclamation} alt='Rectangle24exclamation'/> Oops! Page not found.</h3>
                            <p>
                                We could not find the page you were looking for.
                                Meanwhile, you may <Link to="/">return to HomePage</Link>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Erorr404