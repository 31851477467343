import React from 'react'
import AboutImg from "../images/Rectangle38.jpg";
import Servicesaboutus from "../images/servicesaboutus.jpeg";
import Communityaboutus from "../images/CommunityAboutus.jpg";
import Icon from "../images/Icon.png";
const AboutWorkspaces = () => {
    const itemsData = [
        {
            icon: Icon,
            heading: 'Space',
            desc: "Our coworking spaces foster productivity, creativity, and collaboration through open areas, private offices, meeting rooms, booths, lounges, fast internet, printing, cafes, and enterprise tech.",
            image: AboutImg,
        },
        {
            icon: Icon,
            heading: 'Services',
            desc: "Ofis Square gives members productivity and enhanced experience through space, staff, internet, IT support, bookings, events, wellness, and local partnerships.",
            image: Servicesaboutus,
        },
        {
            icon: Icon,
            heading: 'Community',
            desc: "Ofis Square supports its members by creating a diverse, energising, and collaborative environment through events, programmes, and support.",
            image: Communityaboutus,
        },
    ];
    return (
        <>
            <section className="bepower">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>We believe in workspaces that<br /> work wonders Space</h2>
                        </div>
                        {
                            itemsData.map((item, index) => (
                                <div className="col-md-4 mt-4">
                                    <div className="box text-center" key={index}>
                                        <span><img src={item.icon} alt={`icon-${index}`}/></span>
                                        <h5>{item.heading}</h5>
                                        <p>{item.desc}</p>
                                        <img src={item.image} alt={`image-${index}`} className={`image-${index}`}/>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutWorkspaces