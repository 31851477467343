import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Rectangle24 from "../images/noidasector3meetingroom.jpg";
import Rectangle37 from "../images/noidasectorBoardrooms.jpg";
import Rectangle25 from "../images/noidasector3Rectangle25.jpg";
// import Rectangle37a from "../images/Auditorium.png";
// import Rectangle52 from "../images/Rectangle52.jpg";
import MeetingRoom from "./MeetingRoom";
import ConferenceRoom from "./ConferenceRoom";
import BoardRoom from "./BoardRoom";
import line from "../images/line.svg";
const itemsData = [
    {
        title: "Meeting Rooms",
        imgSrc: Rectangle24, // Use the imported variable
        lineImgSrc: line, // Use the imported variable
        crmLink: {
            scriptSrc: "/MeetingRoom",
        }
    },
    {
        title: "Board Rooms",
        imgSrc: Rectangle37, // Use the imported variable
        lineImgSrc: line, // Use the imported variable
        crmLink: {
            scriptSrc: "/BoardRoom",
        }
    },
    {
        title: "Conference rooms",
        imgSrc: Rectangle25, // Use the imported variable
        lineImgSrc: line, // Use the imported variable
        crmLink: {
            scriptSrc: "/ConferenceRoom",
        }
    },
    // Add more data objects for additional items
];
const options = {
    items: 3,
    nav: true,
    rewind: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 3
        },
        1000: {
            items: 3
        }
    }
};
const NoidaSector3Demandbook = () => {
    const [show, setShow] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const handleShow = (item) => {
        console.log("Selected Item:", item);
        setSelectedItem(item);
        setShow(true);
    };
    const handleClose = () => setShow(false);
    return (
        <>
            <section className="dbook">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>On Demand Bookings</h2>
                            {/* <div className="owl-carousel" id="bookings"> */}
                            <OwlCarousel className="owl-carousel owl-loaded owl-drag" {...options} autoplay margin={30}>
                                {itemsData.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="box">
                                            <figure>
                                                <span>
                                                    <img src={item.imgSrc} alt={item.title} />
                                                </span>
                                            </figure>
                                            <a onClick={() => handleShow(item)}>
                                                <h5>{item.title}</h5>
                                                <span>
                                                    <img src={item.lineImgSrc} alt="Line" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                            {selectedItem && (
                                <Modal show={show} onHide={handleClose}>
                                    <Modal.Header>
                                        <Modal.Title>{selectedItem ? selectedItem.title : "Modal Title"}</Modal.Title>
                                        <Button variant="secondary" onClick={handleClose}>X</Button>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {selectedItem.crmLink.scriptSrc === "/MeetingRoom" && (
                                            <MeetingRoom isOpen={show} onModalClose={handleClose} />
                                        )}
                                        {selectedItem.crmLink.scriptSrc === "/ConferenceRoom" && (
                                            <ConferenceRoom isOpen={show} onModalClose={handleClose} />
                                        )}
                                        {selectedItem.crmLink.scriptSrc === "/BoardRoom" && (
                                            <BoardRoom isOpen={show} onModalClose={handleClose} />
                                        )}
                                    </Modal.Body>
                                </Modal>
                            )}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NoidaSector3Demandbook