import React, { useState, useEffect } from "react";
import axios from 'axios';
import Workspaces from "./Workspaces";
import Upcomingpg from "./Upcomingpg";
import LetsGurugramSpacing from "./LetsGurugramSpacing";
import { Link } from "react-router-dom";
import { MEDIA_API_URL,OFFICE_LOCATION_API_URL } from '../constants/api';

const CoworkingspacesGurugram = () => {
   const [data, setData] = useState([]);
   const [dataSelect, setSelectData] = useState([]);
   const [selectedValue, setSelectedValue] = useState('');
   const [selectedBusinessSuit, setSelectedBusinessSuit] = useState('');
   const handleChange = (e) => {
      setSelectedValue(e.target.value);
   };
   const handleChangeBusinessSuit = (e) => {
      setSelectedBusinessSuit(e.target.value);
   };
   const handleButtonClick = async (e) => {
      e.preventDefault();
      setData([]);
      //console.log('Selected Location:', selectedValue);
      //console.log('Selected Business Suit:', selectedBusinessSuit);
      try {
         const response = await axios.get(`${OFFICE_LOCATION_API_URL}/${selectedValue}`, {
            params: {
               taxonomy: 'category',
               business: selectedBusinessSuit
               // Include additional parameters based on selectedValue and selectedBusinessSuit
            }
         })
         const imageUrl = response.data.acf ? await getImageUrl(response.data.acf.image) : null;
         const locationsData = {
            ...(response.data.acf || {}),
            imageUrl,
         };
         //console.log(locationsData);
         const locationIndex = data.findIndex(prevLocation => prevLocation.id === locationsData.id);

         // If location exists, update it, otherwise add it to the state
         if (locationIndex !== -1) {
            setData(prevData => {
               const newData = [...prevData];
               newData[locationIndex] = locationsData;
               return newData;
            });
         } else {
            setData(prevData => [...prevData, locationsData]);
         }

      } catch (error) {
         console.error('Error Fetching Data:', error);
      }
      // Use a mapping or switch statement to determine the corresponding link for the selected value
      // let link;
      // switch (selectedValue) {
      //    case 'option1':
      //       link = '/OfisSquareInNoida-1.6Lac';
      //       break;
      //    case 'option2':
      //       link = '/OfisSquareInGurugram-47K';
      //       break;
      //    case 'option3':
      //       link = '/Sector62Noida';
      //       break;
      //    case 'option4':
      //       link = '/MgRoadGurugram';
      //       break;
      //    case 'option5':
      //       link = '/SonaRoadGurugram';
      //       break;
      //    case 'option6':
      //       link = '/NoidaSector3';
      //       break;
      //    default:
      //       link = '/';
      // }
      // window.location.href = link;
   }
   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(OFFICE_LOCATION_API_URL, {
               params: {
                  taxonomy: 'category',
                  categories: 11,
               },
            });
            const locationsData = await Promise.all(response.data.map(async post => {
               const imageUrl = await getImageUrl(post.acf.image);
               return {
                  ...post.acf,
                  imageUrl,
               };
            }))
            //const locationsData = response.data.map(post => post.acf);
            setData(locationsData);
         } catch (error) {
            console.error('Error Fetching Data:', error);
         }
      };

      fetchData();
   }, []);
   useEffect(() => {
      const fetchDataSelect = async () => {
         try {
            const response = await axios.get(OFFICE_LOCATION_API_URL, {
               params: {
                  taxonomy: 'category',
               },
            });
            const locationsData = await Promise.all(response.data.map(async post => {
               const imageUrl = await getImageUrl(post.acf.image);
               return {
                  id: post.id,
                  ...post.acf,
                  imageUrl,
               };
            }))
            //const locationsData = response.data.map(post => post.acf);
            setSelectData(locationsData);
         } catch (error) {
            console.error('Error Fetching Data:', error);
         }
      };
      window.scrollTo(0, 0);
      fetchDataSelect();

   }, []);
   const getImageUrl = async (attachmentId) => {
      try {
         const imageResponse = await axios.get(`${MEDIA_API_URL}/${attachmentId}`);
         return imageResponse.data.source_url;
      } catch (error) {
         console.error(`Error fetching image URL for attachment ID ${attachmentId}:`, error);
         return null;
      }
   };
   return (
      <>
         <div className="banner" id="bannerCoworkingGur">
            <div className="container">
               <div className="row">
                  <h1>
                     Coworking Spaces <span> in Gurugram</span>
                  </h1>
               </div>
            </div>
         </div>
         <section className="osyc">
            <div className="container">
               <div className="row">
                  <div className="col-md-6">
                     <h2>Ofis Square in Gurugram</h2>
                     <p>
                        Discover premium coworking spaces in Gurugram that redefine the way you work! Our coworking spaces are strategically located in thriving micro-markets, offering you a dynamic and inspiring work environment.
                     </p>
                     <p>
                        Experience the epitome of flexibility with our customizable office spaces, designed to cater to the diverse needs of modern businesses. Whether you're a startup or a large corporation, our Grade-A commercial real estate provides the perfect backdrop for your success. Enjoy the convenience of our prime locations in Gurugram, effortlessly connecting you to major business hubs. Elevate your work experience with our state-of-the-art facilities and a vibrant community of professionals. If you're in search of the perfect office space in Gurugram, look no further than our coworking spaces. Seize the opportunity today to work in a space that blends innovation, comfort, and style seamlessly. Your ideal workspace awaits!
                     </p>
                     <ul>
                        <li>
                           <div className="box">
                              <h5 className="">
                                 <span></span> 2
                              </h5>
                              <h5 className="opacity">Centers</h5>
                              {/* <p>
                                 MG Road, Gurugram and Sohna Road Gurugram
                              </p> */}
                           </div>
                        </li>
                        <li>
                           <div className="box">
                              <h5 className="">
                                 <span></span> 47,000 + sqft
                              </h5>
                              <h5 className="opacity">Area</h5>
                              {/* <p>
                                 Lorem ipsum dolor sit amet, conser adipiscing elit, sed do
                                 eiusmod tdr incididunt
                              </p> */}
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div className="col-md-6">
                     <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112256.29579266849!2d76.99240719059686!3d28.449137744003398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23a5592c45f3%3A0xf7d6351961809ab5!2sOfis%20square%20%7C%20coworking%20in%20sohna%20road!5e0!3m2!1sen!2sin!4v1706941229045!5m2!1sen!2sin"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                     ></iframe>
                  </div>
               </div>
            </div>
         </section>
         <section className="osc news">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 text-center">
                     <h2>Ofis Square Gurugram</h2>
                     <form className="search">
                        {/* <select>
                           <option>Find a coworking space near me</option>
                           <option>Find a coworking space near me</option>
                        </select> */}
                        <select value={selectedValue} onChange={handleChange}>
                           <option value="">Please Select</option>
                           {dataSelect.map((location, index) => (
                              <option value={location.id} key={index}>{location.title}</option>
                           ))}

                           {/* <option value="Coworking space in Gurugram">Coworking space in Gurugram</option>
                           <option value="Coworking space in sector 62">Coworking space in sector 62</option>
                           <option value="Coworking space in MG road">Coworking space in MG road </option>
                           <option value="Coworking space in Sohna Road">Coworking space in Sohna Road </option>
                           <option value="Coworking space in Noida sector 3">Coworking space in Noida sector 3 </option> */}
                        </select>
                        <select value={selectedBusinessSuit} onChange={handleChangeBusinessSuit}>
                           {data.reduce((uniqueBusinessSuits, location) => {
                              location.workspace_ssolution &&
                                 Object.values(location.workspace_ssolution).forEach((value) => {
                                    const businessSuitsValue = typeof value === 'object' ? value.business_suits : value;
                                    if (!uniqueBusinessSuits.includes(businessSuitsValue)) {
                                       uniqueBusinessSuits.push(businessSuitsValue);
                                    }
                                 });
                              return uniqueBusinessSuits;
                           }, []).map((businessSuitsValue, index) => (
                              <option value={businessSuitsValue} key={index}>
                                 {businessSuitsValue}
                              </option>
                           ))}
                        </select>
                        <button onClick={handleButtonClick}>Search</button>
                        {/* <Link to={selectedValue === 'option1' ? '/OfisSquareInNoida-1.6Lac' :
                           selectedValue === 'option2' ? '/OfisSquareInGurugram-47K' :
                              selectedValue === 'option3' ? '/Sector62Noida' :
                                 selectedValue === 'option4' ? '/MgRoadGurugram' :
                                    selectedValue === 'option5' ? '/SonaRoadGurugram' :
                                       selectedValue === 'option6' ? '/NoidaSector3' :
                                          '/'}>
                        </Link> */}
                     </form>
                  </div>
               </div>

               <div className="row">
                  <div className="col-md-12 text-center">
                     <ul>
                        {data.map((location, index) => (
                           <li key={index}>
                              <Link to={location.page_link}>
                                 <div className="box">
                                    <figure>
                                       <span className="Ofisimg">
                                          <img src={location.imageUrl} alt={`Office Location ${index + 1}`} />
                                       </span>
                                    </figure>
                                    <div className="text">
                                       <h5 className="text-center">{location.title}</h5>
                                       <ul className="ofis-box">
                                          <li>
                                             <p><span><img src="images/Vector1 (4).png" alt="vector1" height="15" width="15" /></span> {location.desk_number} Desks</p>
                                          </li>
                                          <li>
                                             <p><span><img src="images/Vector2 (3).png" alt="vector2" height="20" width="20" /></span>{location.square_feet} SqFt</p>
                                          </li>
                                          <li>
                                             <p><span><img src="images/metro-station.png" alt="metro" height="20" width="20" /></span>{location.location_center}</p>
                                          </li>
                                          <li>
                                             <p><span><img src="images/Vector4 (1).png" alt="vector4" height="20" width="20" /></span>{location.distance_from_airport} KM from airport</p>
                                          </li>
                                       </ul>
                                       <p className="osctext">Choose a workspace solution that suits your business</p>
                                       <ul className="osclastcol">
                                          {location.workspace_ssolution && Object.keys(location.workspace_ssolution).map((key, index) => (
                                             <li key={index}>
                                                {typeof location.workspace_ssolution[key] === 'object' ? (
                                                   location.workspace_ssolution[key].business_suits
                                                ) : (
                                                   location.workspace_ssolution[key]
                                                )}
                                             </li>
                                          ))}
                                       </ul>
                                       <Link to={location.page_link} className="link text-center">Read More</Link>
                                    </div>
                                 </div>
                              </Link>
                           </li>
                        ))}
                       
                     </ul>
                  </div>
               </div>
            </div>
         </section>
         <Workspaces />
         <Upcomingpg />
         <LetsGurugramSpacing />
      </>
   );
};

export default CoworkingspacesGurugram;
