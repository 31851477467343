import React from 'react'
import Rectanglebelowtestimonial from "../images/belowtestimonial.jpg";
import Group332 from "../images/map-location.png";
import Group11 from "../images/table.png";
import Group12 from "../images/phone.png";
import Group13 from "../images/profit.png";
import Lets from './Lets';
const VirtualOffices = () => {
    const itemsData = [
        {
            img: Group332,
            heading: 'Professional Business Address',
            desc: 'Use a real coworking space address—not just a PO box—to give your business the professional image it deserves. Use your Ofis Square address across stationery, website, email signature and listings.'
        },
        {
            img: Group11,
            heading: 'Access To Coworking Spaces',
            desc: 'When you need a physical workspace, access our community lounges and meeting rooms powered by fast WiFi. Network with our members or meet a client in our professional coworking environment.'
        },
        {
            img: Group12,
            heading: 'Personal Phone Answering',
            desc: 'Get your own dedicated business phone number exclusively for you. Our virtual receptionists will professionally handle and forward messages when you are unavailable.'
        },
        {
            img: Group13,
            heading: 'Discounts From Local Businesses',
            desc: 'Take advantage of exclusive deals for healthcare, fitness, restaurants, services and more through our relationships with area businesses.'
        },
    ];
    window.scrollTo(0, 0);
    return (
        <>
            <section className="virtualofc">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-10">
                            <h2>Virtual Office at Ofis Square</h2>
                            <p>With Ofis Square's Virtual Offices, you can operate your business from an eminent coworking space address without the expense of setting up a permanent office. With our coworking locations throughout the city, you can easily enter new markets and position your business alongside leading companies and freelancers.</p>
                            <p>Our virtual office plans provide a professional business address, mail handling, and access to our coworking spaces. Use our spaces for meetings, events, touchdowns and more. Take advantage of our business amenities like high-speed internet, printing, meeting & board rooms and community events.</p>
                            <p>A virtual office at Ofis Square will help you improve your business's presence.</p>
                        </div>
                        <div className="col-md-12 mt-3">
                            <img src={Rectanglebelowtestimonial} alt='Rectanglebelowtestimonial' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefitsvirtualoffice">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <h2 className="text-center">Benefits of a Virtual Office at Ofis Square</h2>
                        </div>
                        {itemsData.map((item, index) => (
                            <>
                                <div className="col-md-3" key={index}>
                                    <div className="box">
                                        <img src={item.img} alt={`Icon ${index + 901}`} />
                                        <h5>{item.heading}</h5>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            </>
                        ))}                        
                    </div>
                </div>
            </section>
            <Lets/>
        </>
    )
}

export default VirtualOffices