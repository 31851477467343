import React from 'react'
import aboutbanner from "../images/MgRectangle31.jpg";
import AboutOperations from './AboutOperations';
import AboutWorkspaces from './AboutWorkspaces';
import AboutWorkEnvironments from './AboutWorkEnvironments';
import AboutTrustedCompanies from './AboutTrustedCompanies';
import LetsAbout from './LetsAbout';
const About = () => {
    
  return (
    <>
    <section className="passion">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h1>Passion-Infused Workspaces Designed Just For You</h1>
                  <img src={aboutbanner} alt='aboutbanner'/>
               </div>
            </div>
         </div>
      </section>
      <div className="redefinesuccess">
         <div className="container">
            <div className="row align-items-center">
               <div className="col-md-7">
                  <h2 className="mb-0">Work towards success and welcome happiness. Find everything at Ofis Square!</h2>
               </div>
               <div className="col-md-5">
                  <p className="mb-0 fw5">Kickstart Your Day in Style! Upgrade your work performance through positive vibes, delicious coffee, and an environment that is friendly to productivity. We have the flexibility you need to scale up and get inspired at Ofis Square, with shared areas, private cabins, and even your own personalised floor available.</p>
               </div>
            </div>
         </div>
      </div>
      <AboutOperations/>
      <div className="aboutvideo">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <iframe src="https://www.youtube-nocookie.com/embed/UeXvCgLN6nM?si=nr4ryMJZTorZy2sB&amp;start=3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
               </div>
            </div>
         </div>
      </div>
      <AboutWorkspaces/>
      {/* <AboutWorkEnvironments/> */}
      <AboutTrustedCompanies/>
      <LetsAbout/>
    </>
  )
}

export default About