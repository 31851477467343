import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
const Navbar = () => {
  const [show, setShow] = useState(false);
  const closeMenu = () => {
    setShow(false);
  };
  const toggleCollapse = () => {
    setShow(!show);
  };
  window.scrollTo(0, 0);
  return (
    <header className="sticky-top">
      <nav className="navbar navbar-expand-sm">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse justify-content-end navbar-collapse ${show ? "show" : ""
              }`} id="collapsibleNavbar"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={closeMenu}>Home</Link>
              </li>
              <li className="nav-item drop">
                <Link to="#" className="nav-link">Locations <span>&#9660;</span></Link>
                <div className="dropbox">
                  <Link to="/coworking-spaces-in-noida" className="nav-link" onClick={closeMenu}>Noida</Link>
                  <Link to="/coworking-spaces-in-gurugram" className="nav-link" onClick={closeMenu}>Gurugram</Link>
                </div>
              </li>
              <li className="nav-item drop">
                <Link to="#" className="nav-link">Solutions <span>&#9660;</span></Link>
                <div className="dropbox">
                  <Link to="/coworking-spaces" className="nav-link" onClick={closeMenu}>Coworking Spaces</Link>
                  <Link to="/managed-offices" className="nav-link" onClick={closeMenu}>Managed offices</Link>
                  <Link to="/virtual-offices" className="nav-link" onClick={closeMenu}>Virtual Offices</Link>
                  <Link to="/event-spaces" className="nav-link" onClick={closeMenu}>Event Spaces</Link>
                </div>
              </li>
              <li className="nav-item">
                <Link to="/About" className="nav-link" onClick={closeMenu}>About</Link>
              </li>
              <li className="nav-item">
                <Link to="/Blog" className="nav-link" onClick={closeMenu}>Blogs</Link>
              </li>
              <li className="nav-item link_btn">
                <Link to="/contact-us" className="nav-link" onClick={closeMenu}>Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
