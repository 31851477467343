import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
//import line from '../images/line.svg';
import lineblogbanner from '../images/blogbanner.jpg';
import Lets from "./Lets";
import { USERS_API_URL,MEDIA_API_URL,NOT_FOUND_IMAGE_URL,POSTS_WITH_EXCLUDED_CATEGORIES_URL } from '../constants/api';
const InNews = () => {
  const [posts, setPosts] = useState([]);
  const [authors, setAuthors] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(POSTS_WITH_EXCLUDED_CATEGORIES_URL);
        const champData = Object.values(response.data);
        const postsWithMedia = await Promise.all(
          champData.map(async (employee) => {
            const media_id = employee.featured_media;
            if (media_id === 0) {
              return { ...employee, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
            } else {
              const featuredResponse = await axios.get(`${MEDIA_API_URL}/${media_id}`);
              const mediaItem = featuredResponse.data;
              return { ...employee, mediaItem };
            }
          })
        );
        setPosts(postsWithMedia);
        const authorsResponse = await axios.get(USERS_API_URL);
        const authorsData = authorsResponse.data.reduce((acc, author) => {
          acc[author.id] = author.name;
          return acc;
        }, {});
        setAuthors(authorsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    window.scrollTo(0, 0);
    fetchPosts();
  }, []);
  const getFirstWords = (content, wordCount) => {
    const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
    const words = strippedContent.split(/\s+/); // Split the text into words
    const firstWords = words.slice(0, wordCount).join(' '); // Take the first N words and join them
    return `${firstWords}...`; // Add ellipsis or modify as needed
  };
  return (
    <>
      <section className="passion">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Press & Media Coverage</h1>
              <img src={lineblogbanner} alt="lineblogbanner" />
            </div>
          </div>
        </div>
      </section>
      <section className="comingsoon">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul>
                {posts.slice(0, 3).map((post) => (
                  <li key={post.id}>
                    <Link to={`/Blogs/${post.slug}`}>
                      <div className="image">
                        {post.mediaItem && <img key={post.mediaItem.id} src={post.mediaItem.source_url} alt="blogpost" />}
                      </div>
                      <div className="text">
                        <h5>{post.title.rendered}</h5>
                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(post.content.rendered, 35) }} />
                        <span>{authors[post.author]} &nbsp;<i>|</i>&nbsp; {new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Lets/>
    </>
  )
}

export default InNews