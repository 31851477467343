import React from 'react'
import { Helmet } from 'react-helmet';
import Navbar from './navbar'
const Header = () => {
  return (
    <>
    {/* Google Site Verification Meta Tag */}
      <Helmet>
        <meta name="google-site-verification" content="google1117d28632134e58.html" />
      </Helmet>
      {/* Navigation Bar */}
       <Navbar/>
    </>
  )
}

export default Header