import React, { useEffect } from 'react'

const LetsAbout = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://ofis-zc1.maillist-manage.in/js/optin.min.js';
        script.onload = () => {
            window.setupSF('sf3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869', 'ZCFORMVIEW', false, 'acc', false, '2');
        };
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    function createMarkup() {
        return {
            __html: `<!--Zoho Campaigns Web-Optin Form Starts Here-->
            <script type="text/javascript" src="https://ofis-zc1.maillist-manage.in/js/optin.min.js" onload="setupSF('sf3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869','ZCFORMVIEW',false,'acc',false,'2')"></script>
            <script type="text/javascript">
                function runOnFormSubmit_sf3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869(th){
                    /*Before submit, if you want to trigger your event, "include your code here"*/
                };
            </script>
            
            <meta content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
            <div id="sf3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869" data-type="signupform">
                <input type="hidden" id="recapTheme" value="2">
                <input type="hidden" id="isRecapIntegDone" value="false">
                <input type="hidden" id="signupFormType" value="QuickForm_Vertical">
                <div id="customForm">
                    <div name="SIGNUP_PAGE" class="large_form_3_css" id="SIGNUP_PAGE">
                        <div name="" changeid="" changename="">
                            <div id="imgBlock" name="LOGO_DIV" logo="true"></div>
                        </div>
                        <br>
                        <div id="signupMainDiv" name="SIGNUPFORM" changeid="SIGNUPFORM" changename="SIGNUPFORM">
                            <div>
                                <div style="position:relative;">
                                    <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                                <tr>
                                                    <td width="10%">
                                                        <img class="successicon" src="https://ofis-zc1.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
                                                    </td>
                                                    <td>
                                                        <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank you for Signing Up</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <form method="POST" id="zcampaignOptinForm" action="https://ofis-zc1.maillist-manage.in/weboptin.zc" target="_zcSignup">
                                    <div id="SIGNUP_BODY_ALL" name="SIGNUP_BODY_ALL">
                                        <h1 id="SIGNUP_HEADING" name="SIGNUP_HEADING" changeid="SIGNUP_MSG" changetype="SIGNUP_HEADER">Coffee Chat</h1>
                                        <div id="SIGNUP_BODY" name="SIGNUP_BODY">
                                            <div>
                                                <div class="" changeid="SIGNUP_MSG" id="SIGNUP_DESCRIPTION" changetype="SIGNUP_DESCRIPTION">Please complete this form to create an account, receive email updates and much more.</div>
                                                <div id="errorMsgDiv">&nbsp;&nbsp;Please correct the marked field(s) below.</div>
                                                <div>
                                                    <div name="fieldsdivSf" class="zcsffieldsdiv">
                                                        <div class="zcsffield " fieldid="118179000000000029">
                                                            <div><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
                                                                <input maxlength="100" placeholder="First Name" name="FIRSTNAME" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
                                                                <span style="display:none" id="dt_FIRSTNAME">1,false,1,First Name,2</span>
                                                            </div>
                                                            
                                                            
                                                            
                                                            
                                                            
                                                        
                                                    <div></div>
                                                    
                                                        
                                                        
                                                        
                                                        
                                                        </div>
                                                        <div class="zcsffield " fieldid="118179000000000038">
                                                            <div><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
                                                                <input maxlength="100" placeholder="Company Name" name="COMPANYNAME" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
                                                                <span style="display:none" id="dt_COMPANYNAME">1,false,1,Company Name,2</span>
                                                            </div><div></div></div>
                                                        <div class="zcsffield " fieldid="118179000000000041">
                                                            <div><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
                                                                <input maxlength="100" placeholder="Phone" name="PHONE" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
                                                                <span style="display:none" id="dt_PHONE">1,false,1,Phone,2</span>
                                                            </div><div></div></div>
                                                        <div class="zcsffield " fieldid="118179000000000023">
                                                            <div><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
                                                                <input maxlength="100" placeholder="Contact Email" name="CONTACT_EMAIL" changeitem="SIGNUP_FORM_FIELD" type="email">&nbsp;
                                                                <span name="SIGNUP_REQUIRED">*</span>
                                                                <span style="display:none" id="dt_CONTACT_EMAIL">1,true,6,Contact Email,2</span>
                                                            </div><div></div></div>
                                                        <div class="zcsffield " fieldid="118179000000000026">
                                                            <div><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
                                                                <input maxlength="100" placeholder="Your Query " name="TITLE" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
                                                                <span style="display:none" id="dt_TITLE">1,false,1,Title,2</span>
                                                            </div><div></div></div>
                                                    </div><!-- Captcha for Signup -->
                                                    <div style="padding: 10px 0px 10px 10px;" id="captchaOld" name="captchaContainer">
                                                        <div>
                                                            <div id="captchaParent">
                                                                <img src="//campaigns.zoho.in/images/refresh_icon.png" onclick="loadCaptcha('https://campaigns.zoho.in/campaigns/CaptchaVerify.zc?mode=generate',this,'#sf3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869');" id="relCaptcha">
                                                                <div id="captchaDiv" captcha="true" name=""></div>
                                                                <input placeholder="Captcha" id="captchaText" name="captchaText" changeitem="SIGNUP_FORM_FIELD" maxlength="100" type="text">
                                                                <span name="SIGNUP_REQUIRED" id="capRequired">*</span>
                                                            </div>
                                                        </div><div></div></div>
                                                    <input type="hidden" id="secretid" value="6LdNeDUUAAAAAG5l7cJfv1AA5OKLslkrOa_xXxLs"><!-- Captcha for Signup End--><!-- Other Lists Subscription Start-->
                                                    <div style="margin-top:20px;" id="selectMailingLists" identity="mailingLists">
                                                        <div name="SIGNUP_FORM_LIST" changetype="SIGNUP_FORM_LIST" class="">You can sign up to receive email updates from topics that may interest you.</div>
                                                        <table width="96%" border="0" cellspacing="0" id="listRelTable" cellpadding="0" class="mt10">
                                                            <tbody></tbody>
                                                        </table>
                                                    </div><div></div>
                                                    <div id="REQUIRED_FIELD_TEXT" changetype="REQUIRED_FIELD_TEXT" name="SIGNUP_REQUIRED">*Required Fields</div>
                                                    <div>
                                                        <input type="button" action="Save" id="zcWebOptin" name="SIGNUP_SUBMIT_BUTTON" changetype="SIGNUP_SUBMIT_BUTTON_TEXT" value="Join Now">
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- Do not edit the below Zoho Campaigns hidden tags -->
                                        <input type="hidden" id="fieldBorder" value="">
                                        <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" onload="">
                                        <input type="hidden" name="viewFrom" id="viewFrom" value="URL_ACTION">
                                        <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                                        <input type="hidden" id="lD" name="lD" value="">
                                        <input type="hidden" name="emailReportId" id="emailReportId" value="">
                                        <input type="hidden" name="zx" id="cmpZuid" value="1df981d41f">
                                        <input type="hidden" name="zcvers" value="2.0">
                                        <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                                        <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                                        <input type="hidden" id="zcld" name="zcld" value="">
                                        <input type="hidden" id="zctd" name="zctd" value="">
                                        <input type="hidden" id="document_domain" value="">
                                        <input type="hidden" id="zc_Url" value="ofis-zc1.maillist-manage.in">
                                        <input type="hidden" id="new_optin_response_in" value="0">
                                        <input type="hidden" id="duplicate_optin_response_in" value="0">
                                        <input type="hidden" id="zc_formIx" name="zc_formIx" value="3zb70dcce40918254f214d1939fb8dc91d7161355f2f464ee18d4a0294a49b3869"><!-- End of the campaigns hidden tags --></div>
                                </form>
                            </div>
                            <div id="privacyNotes" identity="privacyNotes">
                                <span>Note: It is our responsibility to protect your privacy and we guarantee that your data will be completely confidential.</span>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" id="isCaptchaNeeded" value="true">
                    <input type="hidden" id="superAdminCap" value="0">
                    <img src="https://ofis-zc1.maillist-manage.in/images/spacer.gif" onload="referenceSetter(this)" id="refImage" style="display:none;">
                </div>
            </div>
            <div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"></div>
            <div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
                <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
                    <img src="https://ofis-zc1.maillist-manage.in/images/videoclose.png">
                </span>
                <div id="zcOptinSuccessPanel"></div>
            </div>
            
            <!--Zoho Campaigns Web-Optin Form Ends Here-->`    };
    };
    return (
        <>
            <div className="lets" id="AboutusLets">
                <div className="box">
                    <h2>Coffee's ready to discuss your requirements—let's chat!</h2>
                    <p>Share your needs in a minute. Our expert will connect soon. Call +91 95600 50321 for swift solutions. Thanks!</p>
                    {/* <script type='text/javscript' src="https://campaigns.zoho.in/js/zc.iframe.js"></script>
                    <iframe frameborder="0" id="iframewin" width="100%" height="950px" src="https://ofis-zc1.maillist-manage.in/ua/Optin?od=1a1e3db317372&zx=1df9653ea4&lD=11a3db2ca2ef5a21&n=11699f74da04217&sD=11a3db2ca2ef5a33"></iframe> */}
                    <div className="mainLets" dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </div>
        </>
    )
}

export default LetsAbout